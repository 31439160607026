import {
    Box,
    Button,
    ButtonDropdown,
    CollectionPreferences,
    Header,
    Pagination,
    SpaceBetween,
    Table,
} from "@amzn/awsui-components-react";
import { defaultPagingOptions } from "common/utils";
import { FunctionComponent } from "react";
import EmptyTableBox from "../../common/EmptyTableBox/EmptyTableBox";
import { getString } from "../../common/ui-string-labels/ui-strings-utils";
import { ImportsTableProps, useImportsTable } from "./useImportsTable";


export const ImportsTable: FunctionComponent<ImportsTableProps> = (props) => {
    const { actions, state } = useImportsTable(props);
    const { onPreferencesConfirmHandler } = actions;
    const { pagesCount, currentColumnDefinitions, currentPageIndex, currentPreferences, loading, visibleContentColumnsOptions } = state;

    return (
        <Box data-testid="imports-box">
            <Table
                data-testid="imports-table"
                loading={loading}
                header={
                    <SpaceBetween size="m">
                        <Header
                            data-testid="imports-table-header"
                            variant="awsui-h1-sticky"
                            actions={
                                <>
                                    <ButtonDropdown
                                        items={[
                                            {
                                                text: getString("imports.table.createSubLines"),
                                                id: "btn-create-sub-lines",
                                                disabled: false
                                            },
                                            {
                                                text: getString("imports.table.updateSubLines"),
                                                id: "btn-update-sub-lines",
                                                disabled: false
                                            }
                                        ]}
                                    >
                                        {getString("imports.table.sublines")}
                                    </ButtonDropdown>
                                    <Button
                                        data-testid="imports-assignee-button"
                                        variant="normal" >
                                        {getString("imports.table.asignee")}
                                    </Button>
                                    <Button
                                        data-testid="imports-comments-button"
                                        variant="normal" >
                                        {getString("imports.table.comments")}
                                    </Button>
                                </>
                            }
                        >
                            {getString("imports.table.headerTitle")}
                        </Header>
                        <Box color="text-status-inactive">{getString("imports.table.headerDescription")}</Box>
                    </SpaceBetween>
                }
                pagination={
                    <Pagination
                        currentPageIndex={currentPageIndex.index}
                        pagesCount={pagesCount}
                        ariaLabels={{
                            nextPageLabel: getString("common.pagination.nextPage"),
                            previousPageLabel: getString("common.pagination.nextPage"),
                            pageLabel: (pageNumber: number) =>
                                getString("common.pagination.currentPage", undefined, { pageNumber, pagesCount }),
                        }}
                    />
                }
                empty={<EmptyTableBox />}
                stripedRows={currentPreferences.stripedRows}
                preferences={
                    <CollectionPreferences
                        title={getString("common.preferences.title")}
                        confirmLabel={getString("common.preferences.confirmLabel")}
                        cancelLabel={getString("common.preferences.cancelLabel")}
                        pageSizePreference={{
                            title: getString("common.preferences.pageSize.title"),
                            options: defaultPagingOptions,
                        }}
                        stripedRowsPreference={{
                            label: getString("common.preferences.stripedRows.label"),
                            description: getString("common.preferences.stripedRows.description"),
                        }}
                        visibleContentPreference={{
                            title: getString("common.preferences.visibleContentPreference.title"),
                            options: [
                                {
                                    label: getString("common.preferences.visibleContentPreference.columns"),
                                    options: visibleContentColumnsOptions,
                                },
                            ],
                        }}
                        preferences={currentPreferences}
                        onConfirm={onPreferencesConfirmHandler}
                    />
                }
                columnDefinitions={currentColumnDefinitions}
                loadingText={getString("common.loading")}
                items={[]}
                resizableColumns={true}
                selectionType="multi"
                variant="full-page"
                wrapLines
            />
        </Box>
    );
};

export default ImportsTable;
