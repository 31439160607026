import { Box, Link } from '@amzn/awsui-components-react';
import { Order, OrderItem } from '@amzn/ito-client';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { ITMPV1_WEBSITE_URL } from 'configuration/config';
import { useUpdateOrdersStatus } from 'hooks';
import { useState } from 'react';
import { CSRFService } from 'services/csrf-service';

export interface useOrderEditItems {
  currentOrder: Order | undefined,
  canBeEdited: boolean,
  orderReference: string,
  editedOrderId: string | undefined,
  orderSelected: Order | undefined,
  showSplitPanel: boolean,
  lastLineItemSelected: OrderItem | undefined,
  selectedItems: OrderItem[],
  isReadOnly: boolean,
  inputComment: string,
  inputNote: string
}

export interface useOrderEditActions {
  setCurrentOrderInformation: (order: Order) => void,
  setOrderIdToBreadcrumb: (id: string) => void
  setSelectedLineItems: (items: OrderItem[]) => void,
  reloadOrder: (id: string | undefined) => void,
  showSublinePanel: (show: boolean) => void,
  updateSubLineItemSelected: (orderSelected: Order | undefined, lineItemSelected: OrderItem | undefined) => void,
  flagOrderWithFirstEdit: (ordersToUpdate?: string[]) => void,
  setInputComment: (value: string) => void,
  setInputNote: (value: string) => void
}

export interface useOrderEditResult {
  items: useOrderEditItems,
  actions: useOrderEditActions,
}

export function useOrderEdit(flashBarActions: useFlashBarItemsActions, isExternalViewMode?: boolean): useOrderEditResult {

  // Order
  const { doUpdateOrdersById } = useUpdateOrdersStatus();

  const [canBeEdited, setCanBeEdited] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<Order | undefined>(undefined);
  const [orderReference, setOrderReference] = useState("");
  const [editedOrderId, setEditedOrderId] = useState<string | undefined>("");
  const [orderSelected, setOrderSelected] = useState<Order | undefined>(undefined);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [inputComment, setInputComment] = useState("");
  const [inputNote, setInputNote] = useState("");


  // Line and sub-line item management
  const [showSplitPanel, setShowSplitPanel] = useState(false);
  const [selectedItems, setSelectedItems] = useState<OrderItem[]>([]);
  const [lastLineItemSelected, setLastLineItemSelected] = useState<OrderItem | undefined>(undefined);

  // Actions
  const setOrderIdToBreadcrumb = (id: string) => setOrderReference(id);
  const setSelectedLineItems = (items: OrderItem[]) => setSelectedItems(items);
  const showSublinePanel = (show: boolean) => setShowSplitPanel(show);
  const reloadOrder = (id: string | undefined) => setEditedOrderId(id);
  const updateSubLineItemSelected = (orderSelected: Order | undefined, lineItemSelected: OrderItem | undefined) => {
    const shouldShowSplitPanel = !lineItemSelected ? false : true;
    setShowSplitPanel(shouldShowSplitPanel);
    setOrderSelected(orderSelected);
    setLastLineItemSelected(lineItemSelected);
  };
  const setCurrentOrderInformation = (order: Order) => {
    setCurrentOrder(order);
    setOrderReference(order.orderReference ?? "");

    // Enable editing actions
    const isReadOnlyFlag = isReadOnlyOrder(order.details);
    if (!isExternalViewMode && !isReadOnlyFlag) {
      setCanBeEdited(true);
    }
    // Show read only banner
    if (isReadOnlyFlag) {
      setIsReadOnly(true);
      const orderIdITMPv1 = (order.details as any).legacyOrderId;
      const itmpv1EditLink = orderIdITMPv1 ? `${ITMPV1_WEBSITE_URL}procurement/hardware_orders/${orderIdITMPv1}/edit` : ITMPV1_WEBSITE_URL;
      flashBarActions.addInfoFlashBarItem(
        "This order can not be edited",
        <Box>
          {`It was previously edited in ITMPv1. Please navigate to the following link `}<Link color="inverted" href={itmpv1EditLink} variant="secondary" external>{itmpv1EditLink}</Link>
        </Box>
      );
    }
  }

  const flagOrderWithFirstEdit = (ordersToUpdate?: string[]) => {
    const firstEdit = currentOrder ? (currentOrder?.details as any).firstEdit : undefined;

    if (!currentOrder && !ordersToUpdate) {
      return;
    }

    // Order is already flagged
    if (firstEdit && firstEdit === "ham") {
      return;
    }

    const ordersList = ordersToUpdate ?? [currentOrder?.orderId!];
    doLockOrderUpdate(ordersList);
  }


  const doLockOrderUpdate = async (orders: string[]) => {
    const csrfService = new CSRFService();
    const token = await csrfService.getToken();

    // Async request to flag the order
    doUpdateOrdersById(orders, { details: { firstEdit: "ham" } }, token);
  }

  return {
    items: {
      currentOrder,
      canBeEdited,
      orderReference,
      editedOrderId,
      orderSelected,
      showSplitPanel,
      lastLineItemSelected,
      selectedItems,
      isReadOnly,
      inputComment,
      inputNote
    },
    actions: {
      setCurrentOrderInformation,
      setOrderIdToBreadcrumb,
      setSelectedLineItems,
      showSublinePanel,
      reloadOrder,
      updateSubLineItemSelected,
      flagOrderWithFirstEdit,
      setInputComment,
      setInputNote
    }
  };
}

function isReadOnlyOrder(details: any): boolean {
  const firstEditFlag = (details as any).firstEdit;
  if (firstEditFlag && firstEditFlag !== "ham") {
    return true;
  }

  return false;
}