import { buildColumnDefinitions, buildVisibleContentOptions, defaultPagingOptions } from "common";
import { usePreferences } from "hooks/usePreferences";
import { CurrentPage } from "common/utils/config-models";
import { useState } from "react";
import { ColumnSetting } from "common/utils/config-models";
import orderItemsTableConfig from "./exports.table-config.json";
import { useFlashBarItemsActions } from "common/UseFlashBarItems/useFlashBarItems";
import { FilesService } from "services/files-service";
import { NonCancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events";
import { CollectionPreferencesProps, TableProps } from "@amzn/awsui-components-react";
import { Preferences } from "common/ui-helpers";

const columns: ColumnSetting[] = orderItemsTableConfig.columns;

export interface ExportsTableProps {
    useFlashBarItemsActions: useFlashBarItemsActions;
}


export interface useExportsTableResult {
    actions: {
        doExportItemsAction: () => Promise<void>;
        onPreferencesConfirmHandler: NonCancelableEventHandler<CollectionPreferencesProps.Preferences<any>>;
    };
    state: {
        pagesCount: number;
        isReportLoading: boolean;
        loading: boolean;
        currentPreferences: Preferences;
        currentPageIndex: CurrentPage;
        currentColumnDefinitions: TableProps.ColumnDefinition<any>[];
        visibleContentColumnOptions: CollectionPreferencesProps.VisibleContentOption[];
    }
}

export const useExportsTable = (props: ExportsTableProps) => {
    const [currentPageIndex, setCurrentPageIndex] = useState<CurrentPage>({ index: 1, isPageReset: true });
    const [loading] = useState(false);
    const [isReportLoading, setIsReportLoading] = useState(false);

    // Ready to display
    let pagesCount = 0;

    const PREFERENCES_KEY = "ExportsPreferences";
    const visibleContentColumnsOptions = buildVisibleContentOptions(columns);

    // Preferences
    const { currentPreferences, onPreferencesConfirmHandler } = usePreferences({
        preferencesKey: PREFERENCES_KEY,
        refreshData: () => {},
        columnsSettings: columns,
        defaultPaging: defaultPagingOptions[0].value,
    });

    const currentColumnDefinitions = buildColumnDefinitions(columns, currentPreferences.visibleContent);

    const doExportItemsAction = async (type: string = "ITSE") => {
        try {
            setIsReportLoading(true);
            await FilesService.instance.downloadItemsReport(type);
        } catch (error) {
            props.useFlashBarItemsActions.addErrorFlashBarItem(
                (error as Error).message,
            );
        } finally {
            setIsReportLoading(false);
        }
    }

    return {
        actions: {
            doExportItemsAction,
            onPreferencesConfirmHandler
        },
        state: {
            pagesCount,
            isReportLoading,
            loading,
            currentPreferences,
            currentPageIndex,
            currentColumnDefinitions,
            visibleContentColumnsOptions
        }
    }
}