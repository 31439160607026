import { GetItemAttachmentsResponse, GetItemAttachmentsCommandOutput, GetItemAttachmentsCommandInput, GetItemAttachmentResponse, GetItemAttachmentRequest, GetItemAttachmentCommandOutput, PostItemAttachmentCommandInput, PostItemAttachmentCommandOutput, Attachment } from "@amzn/ito-client";
import { useState, useEffect } from "react";
import { getITOClient } from "./hooks-helpers";
import { UserPermissions } from "services/user-permissions";
import { lookup } from "mime-types";

// Client
const { client, requestHandler } = getITOClient();

export interface GetAttachmentsProps {
    pageSize?: number;
    pageIndex?: number;
    orderId: string | undefined;
}

export interface GetItemAttachmentProps {
    attachmentId: string;
}

export interface useGetAttachmentsResponse {
    data: Attachment[] | undefined;
    isLoading: boolean;
    error: Error | undefined;
    doGetAttachments: () => void;
}

export function useGetOrderAttachments(
    props: GetAttachmentsProps,
    defaultValue?: GetItemAttachmentsCommandOutput,
): useGetAttachmentsResponse {
    const [data, setData] = useState<Attachment[] | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    function doGetAttachments() {
        setLoading(true);
        const request: GetItemAttachmentsCommandInput = { taxonomyId: props.orderId };
        request.pageSize = 99;
        client.getItemAttachments(request, (err: any, response?: GetItemAttachmentsCommandOutput | undefined) => {
            setLoading(false);

            if (!response || !response.attachments || err) {
                setError({ name: "Unable to get the order details", message: `Error: ${err}` });
            } else {
                setError(undefined);
            }
            setData(response?.attachments);
        });
    }

    return { data, error, isLoading, doGetAttachments };
}

export interface useGetItemAttachmentResponse {
    data: GetItemAttachmentCommandOutput | undefined;
    error: Error | undefined;
    isLoading: boolean;
    doGetItemAttachment: (props: GetItemAttachmentProps) => void;
}

export function useGetItemAttachment(
    props?: GetItemAttachmentProps,
    defaultValue?: GetItemAttachmentCommandOutput
): useGetItemAttachmentResponse {
    const [data, setData] = useState<GetItemAttachmentCommandOutput | undefined>(defaultValue);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    function doGetItemAttachment(props: GetItemAttachmentProps) {
        setLoading(true);
        const request: GetItemAttachmentRequest = { attachmentId: props.attachmentId };

        client.getItemAttachment(request, (err: any, response?: GetItemAttachmentCommandOutput | undefined) => {
            if (!response || !response.preSignedUrl || err) {
                setError({ name: "Unable to get the attachment pre-signed url", message: `Error: ${err}` });
                setLoading(false);
            } else {
                setError(undefined);
            }
            setData(response);
        });
        setLoading(false);
    }

    if (!defaultValue) {
        useEffect(() => {
            if (props) {
                doGetItemAttachment(props);
            }
        }, []);
    }

    return { data, error, isLoading, doGetItemAttachment };
}

export function useUploadFile() {
    const [error, setError] = useState<Error | null>(null);
    const [response, setResponse] = useState<PostItemAttachmentCommandOutput | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    async function doUploadFile(taxonomyId: string, attachment: File, contentType: string, fileName: string, csrfToken: string) {
        
        if (!contentType) {
            contentType = lookup(fileName) || "";
        }

        setLoading(true);
        if (!attachment) {
            setError(new Error("No attachment found"));
            return;
        }
        const arrayBuffer = await attachment.arrayBuffer();
        const attachmentFile = new Uint8Array(arrayBuffer);
        requestHandler.pushHeader("x-csrf-token", csrfToken);
        const uploadedBy = UserPermissions.instance.username;
        const encodedFileName = encodeURIComponent(fileName.split('.').shift()!);
        const request: PostItemAttachmentCommandInput = {
            taxonomyId,
            attachment: attachmentFile,
            contentType,
            fileName: encodedFileName,
            uploadedBy,

        };

        try {
            const response = await client.postItemAttachment(request);
            if (!response) {
                const err = { name: "No response", message: "No response" };
                console.error(err);
                setError(err);
            }
            setError(null);
            setResponse(response);
        }
        catch (err) {
            console.error(err);
            setError(err as Error);
        };
        setLoading(false);
    }

    return { isLoading, response, error, doUploadFile };
}
