import {
    Box,
    Button,
    CollectionPreferences,
    Header,
    Pagination,
    SpaceBetween,
    Table,
} from "@amzn/awsui-components-react";
import { defaultPagingOptions } from "common/utils";
import { FunctionComponent } from "react";
import EmptyTableBox from "../../common/EmptyTableBox/EmptyTableBox";
import { getString } from "../../common/ui-string-labels/ui-strings-utils";
import { ExportsTableProps, useExportsTable } from "./useExportsTable";


export const ExportsTable: FunctionComponent<ExportsTableProps> = (props) => {
    const {actions, state} = useExportsTable(props);
    const { doExportItemsAction, onPreferencesConfirmHandler } = actions;
    const { pagesCount, currentColumnDefinitions, currentPageIndex, currentPreferences, isReportLoading, loading, visibleContentColumnsOptions } = state;


    return (
        <Box data-testid="exports-box">
            <Table
                data-testid="exports-table"
                loading={loading}
                header={
                    <SpaceBetween size="m">
                        <Header
                            data-testid="exports-table-header"
                            variant="awsui-h1-sticky"
                            actions={
                                <SpaceBetween size="s" direction="horizontal">
                                    <Button
                                        data-testid="export-amazonian-orders-button"
                                        variant="normal"
                                        onClick={() => doExportItemsAction("AMAZONIAN")}
                                        loading={isReportLoading}
                                        disabled={isReportLoading}
                                    >
                                        {getString("exports.table.exportAmazonians")}
                                    </Button>
                                    <Button
                                        data-testid="export-bulk-ordering-button"
                                        variant="normal"
                                        onClick={() => doExportItemsAction("ITSE")}
                                        loading={isReportLoading}
                                        disabled={isReportLoading}
                                    >
                                        {getString("exports.table.exportBulkOrdering")}
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            {getString("exports.table.headerTitle")}
                        </Header>
                        <Box color="text-status-inactive">{getString("exports.table.headerDescription")}</Box>
                    </SpaceBetween>
                }
                pagination={
                    <Pagination
                        currentPageIndex={currentPageIndex.index}
                        pagesCount={pagesCount}
                        ariaLabels={{
                            nextPageLabel: getString("common.pagination.nextPage"),
                            previousPageLabel: getString("common.pagination.nextPage"),
                            pageLabel: (pageNumber: number) =>
                                getString("common.pagination.currentPage", undefined, { pageNumber, pagesCount }),
                        }}
                    />
                }
                empty={<EmptyTableBox />}
                stripedRows={currentPreferences.stripedRows}
                preferences={
                    <CollectionPreferences
                        title={getString("common.preferences.title")}
                        confirmLabel={getString("common.preferences.confirmLabel")}
                        cancelLabel={getString("common.preferences.cancelLabel")}
                        pageSizePreference={{
                            title: getString("common.preferences.pageSize.title"),
                            options: defaultPagingOptions,
                        }}
                        stripedRowsPreference={{
                            label: getString("common.preferences.stripedRows.label"),
                            description: getString("common.preferences.stripedRows.description"),
                        }}
                        visibleContentPreference={{
                            title: getString("common.preferences.visibleContentPreference.title"),
                            options: [
                                {
                                    label: getString("common.preferences.visibleContentPreference.columns"),
                                    options: visibleContentColumnsOptions,
                                },
                            ],
                        }}
                        preferences={currentPreferences}
                        onConfirm={onPreferencesConfirmHandler}
                    />
                }
                columnDefinitions={currentColumnDefinitions}
                loadingText={getString("common.loading")}
                items={[]}
                resizableColumns={true}
                selectionType="multi"
                variant="full-page"
                wrapLines
            />
        </Box>
    );
};

export default ExportsTable;
