import {
    Alert,
    ContentLayout,
    Header,
    Spinner,
    Tabs,
    TextContent
} from '@amzn/awsui-components-react';
import {
    OrderSummaryTab,
    ItemsRequestedTab,
    MessagesTab,
} from './tabs';
import { getString } from 'common';
import { useParams } from 'react-router-dom';
import { useGetComments, useGetNotes, useGetOrder, useGetOrderAttachments } from 'hooks';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { useEffect, useState } from 'react';
import { InternalCommentsTab } from './tabs/InternalCommentsTab';
import { useOrderEditActions, useOrderEditItems } from 'views/OrdersDetailsPage/useOrderEdit';
import { GetCommentsCommandOutput } from '@amzn/ito-client';

const stringPath = "orderDetails.tabs";

export interface OrderDetailsTabContainerProps {
    useOrderEditItems: useOrderEditItems;
    useOrderEditActions: useOrderEditActions;
    useFlashBarItemsActions: useFlashBarItemsActions;
}

const OrderDetailsTabContainer = (props: OrderDetailsTabContainerProps) => {
    const [showNotes, setShowNotes] = useState<boolean>(false);
    const [showComments, setShowComments] = useState<boolean>(false);

    const showIfReady = (tab: JSX.Element) => isLoading ? <Spinner data-testid='spinner' /> : tab;

    // Retrieve Taxonomy ID from URL params
    const { id } = useParams();

    // Get order information
    const { data, isLoading, error, doGetOrder } = useGetOrder(id);
    const { comments, errorForGetComments, doGetComments, setComments } = useGetComments(undefined);
    const { notes, errorForGetNotes, doGetNotes, setNotes } = useGetNotes(undefined);
    const itemsAmount = data?.items?.length ?? "-";

    // Display order id in breadcrumbs and get comments
    useEffect(() => {
        if (data && data.orderReference) {
            props.useOrderEditActions.setCurrentOrderInformation(data);

            // When reloading the order, keep the last item selected
            const previouslySelectedItem = data.items?.find(item => item.itemId === props.useOrderEditItems.lastLineItemSelected?.itemId)
            props.useOrderEditActions.setSelectedLineItems(previouslySelectedItem ? [previouslySelectedItem] : [])

            doGetComments({ taxonomyId: data!.orderId! });
            doGetNotes({ taxonomyId: data!.orderId! });
        }
    }, [data]);

    // Display error
    useEffect(() => {
        if (error && error.message) {
            props.useFlashBarItemsActions?.addErrorFlashBarItem(error.message);
        }
    }, [error]);

    // Display comments
    useEffect(() => {
        if (comments) {
            setShowComments(!errorForGetComments);
        }
        if (errorForGetComments && errorForGetComments.$metadata.httpStatusCode !== 403) {
            props.useFlashBarItemsActions?.addErrorFlashBarItem(errorForGetComments.message);
        }
    }, [comments, errorForGetComments]);

    // Display notes
    useEffect(() => {
        if (notes) {
            setShowNotes(!errorForGetNotes);
        }
        if (errorForGetNotes && errorForGetNotes.$metadata.httpStatusCode !== 403) {
            props.useFlashBarItemsActions?.addErrorFlashBarItem(errorForGetNotes.message);
        }
    }, [notes, errorForGetNotes]);

    // Attachments
    const attachmentHook = useGetOrderAttachments({ orderId: id })
    useEffect(() => {
        attachmentHook.doGetAttachments()
    }, [id]);

    // Refresh order details page when edited
    useEffect(() => {
        const fetchData = async () => {
            doGetOrder(props.useOrderEditItems.editedOrderId);
        }
        if (props.useOrderEditItems.editedOrderId && props.useOrderEditItems.editedOrderId !== "") {
            fetchData();
            props.useOrderEditActions.reloadOrder("");
        }
    }, [props.useOrderEditItems.editedOrderId]);

    const getPageTitle = (): string => 
        !error ? `${getString("headerTitle", stringPath)} ${data?.orderReference ?? ""}` : getString("notFound.title", stringPath);

    function getTabs() {
        const tabs = [
            {
                label: getString("orderSummary.title", stringPath),
                id: "orderSummary",
                content: showIfReady(
                    <OrderSummaryTab
                        data-testid="order-summary-tab"
                        order={data!}
                        useOrderEditItems={props.useOrderEditItems}
                        useOrderEditActions={props.useOrderEditActions}
                        flashBarItemsActions={props.useFlashBarItemsActions!}
                        attachmentsHook={attachmentHook}
                    />)
            },
            {
                label: `${getString("itemsRequested.title", stringPath)} (${itemsAmount})`,
                id: "itemsRequested",
                content: showIfReady(
                    <ItemsRequestedTab
                        data-testid="items-requested-tab"
                        order={data!}
                        items={data?.items!}
                        selectedItems={props.useOrderEditItems.selectedItems}
                        useOrderEditItems={props.useOrderEditItems}
                        useOrderActions={props.useOrderEditActions}
                        flashBarItemsActions={props.useFlashBarItemsActions!}
                    />)
            }
        ];
        if (showComments) {
            tabs.push({
                label: getString("messages.title", stringPath),
                id: "messages",
                content: showIfReady(
                    <MessagesTab
                        data-testid="messages-tab"
                        order={data!}
                        useOrderEditItems={props.useOrderEditItems}
                        useOrderEditActions={props.useOrderEditActions}
                        flashBarItemsActions={props.useFlashBarItemsActions!}
                        comments={comments as GetCommentsCommandOutput}
                        setDefaultComments={setComments}
                        inputComment={props.useOrderEditItems.inputComment}
                        setInputComment={props.useOrderEditActions.setInputComment}
                    />)
            });
        }

        if (showNotes) {
            tabs.push({
                label: getString("internalComments.title", stringPath),
                id: "internalComments",
                content: showIfReady(
                    <InternalCommentsTab
                        order={data!}
                        useOrderEditItems={props.useOrderEditItems}
                        useOrderEditActions={props.useOrderEditActions}
                        flashBarItemsActions={props.useFlashBarItemsActions!}
                        notes={notes}
                        setDefaultNotes={setNotes}
                        inputNotes={props.useOrderEditItems.inputNote}
                        setInputNotes={props.useOrderEditActions.setInputNote}
                    />)
            })
        }
        return tabs;
    }

    const getNotFoundAlert = () => (
        <Alert
            statusIconAriaLabel="Warning"
            type="warning"
            header={getString("notFound.description", stringPath)}
            data-testid="order-not-found-alert" >
            <TextContent>
                <strong>{getString("notFound.tipKeyword", stringPath)}</strong>{getString("notFound.tip", stringPath)}
            </TextContent>
        </Alert>
    );

    return (
        <ContentLayout
            header={
                <Header data-testid="order-details-header" variant="h1">
                    {showIfReady(<div>{getPageTitle()}</div>)}
                </Header>
            }
        >
            {/* Show tabs */}
            { !isLoading && !error && <Tabs variant="container" tabs={getTabs()} /> }

            {/* Order was not found */}
            { !isLoading && error && getNotFoundAlert() }
        </ContentLayout>
    );
}

export default OrderDetailsTabContainer;