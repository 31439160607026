import { Flashbar } from "@amzn/awsui-components-react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from "../../common/navigation/SideNavigationMenu";
import { useState } from "react";
import { useFlashBarItems } from "../../common/UseFlashBarItems/useFlashBarItems";
import Paths from "../../common/paths/paths";
import ImportsTable from "components/ImportsTable/ImportsTable";

const breadcrumbs = {
    items: [
        { text: Paths.Admin.name, href: Paths.Admin.url },
        { text: Paths.imports.name, href: Paths.imports.url },
    ],
};

const ImportsPage = () => {
    const flashBarItemsHook = useFlashBarItems();
    const [navigationOpen, setNavigationOpen] = useState(false);

    return (
        <AppLayout
            breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
            navigation={<SideNavigationMenu />}
            navigationOpen={navigationOpen}
            notifications={<Flashbar items={flashBarItemsHook.flashBarItems} stackItems />}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
            toolsHide={true}
            content={
                <ImportsTable useFlashBarItemsActions={flashBarItemsHook.actions}></ImportsTable>
            }
        />
    );
};

export default ImportsPage;
